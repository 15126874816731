<template>
  <div id="like">
    <Else></Else>
      <div class="like-box">
        <div class="like-box-middle">
            <div class="like-box-detail" v-show="datashow">
                <div class="like-box-detail-total">注册商标：<span>{{this.$route.query.type}}</span>，系统为您检索到<span>{{total}}</span>条相关商标</div>
                <div>
                    <div class="like-box-detail-box" v-for="(v,k) in list" :key="k">
                        <div class="like-box-detail-box-img">
                            <img :src="v.trademarkImgUrl?v.trademarkImgUrl:require('/static/images/no.png')" alt="">
                        </div>
                        <div class="like-box-detail-box-des">
                            <div>
                                <span>申请人：</span>
                                <span>{{v.comName}}</span>
                            </div>
                            <div>
                                <span>申请日期：</span><span>{{v.requestDate}}</span>
                                <span>商标状态：</span><span>{{v.trandemarkStatus}}</span>
                                <span>注册号：</span><span>{{v.requestNum}}</span>
                                <span>商标类别：</span><span>{{v.oneCate}}</span>
                            </div>
                        </div>
                        <div class="like-box-detail-box-do">
                            <p id="p1" @click="tolikedetail(v.requestNum,v.oneCate,v.comName)">查看详情</p>
                        </div>
                    </div>
                    <div class="like-page">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="currentPage1"
                                :page-size="pageSize"
                                layout="prev, pager, next"
                                :total="total">
                            </el-pagination>
                        </div>
                </div>
            </div>
            <div style="margin-top:200px;height:300px;text-align: center;font-size:18px;" v-show="!datashow">暂无数据</div>
        </div>
      </div>
  </div>
</template>

<script>
import Else from '../ui/else.vue';
export default {
  name: 'like',
  data(){
      return{
         list:[],
          total:0,
          currentPage1: 1,
          pageSize: 10,
          num:0,
        
        datashow:true,
      }
  },
  components: {
      Else
  },
  computed:{
      
  },
  methods:{
      getdata(){
          this.$http.post('https://aipro.guolaoai.com/private/getRateSimilar',{
                    keyword:this.$route.query.type,
                    group:this.$route.query.num,
                    size:10,    
                    index:this.currentPage1
                }).then(res=>{
                    // console.log(res)
                    
                    if(res.data.code == "200"){
                        this.list = res.data.data.list;
                        this.total = res.data.data.total;
                       //子向父发送数据
                        this.$nextTick(()=>{
                            var height;
                            if(this.total>3){
                                 height =  document.documentElement.scrollHeight;
                            }else{
                                height = 715;
                            }
                            console.log(height)
                            window.parent.postMessage(
                            {
                                data: height,
                            },
                            "*"
                            );
                        })
                    }else{
                        
                        this.datashow = false;
                    }
                        
                    
                })
      },
      handleSizeChange(val) {
              this.pageSize = val;
              this.getdata();
            },
            
        handleCurrentChange(val) {
            this.currentPage1 = val;
            this.getdata();
        },
        tolikedetail(value1,value2,val3){
              this.$router.push({ path: '/brandDetail', query: { regNum:value1,
                   category:value2,companyName:val3}})
        },
       
  },
  mounted(){
      this.getdata();
  }
}

</script>

<style scoped>
    .like-box{
        background-color: #fafafa;
        padding-top:30px;
        /* min-height: 555px; */
    }
    .like-box-middle{
        width: 1200px;
        margin: 0 auto;
    }
    .like-box-title{
        height: 40px;
        border-bottom:2px solid #EB5E00;
    }
    .like-box-title>div{
        width: 1200px;
        margin: 0 auto;
        height: 40px;
    }
    .seActive{
        color: red!important;
    }
    .like-box-title>div>div{
        float: left;
        width: 120px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-family: "PingFangSC-Semibold";
        font-size: 20px;
        /* font-weight: 600; */
        color: #333333;
        cursor: pointer;
    }
    .like-box-title>div>div:nth-of-type(1){
        margin-left: 40px;
    }
    .excat .div1,.like .div2,.apply .div3,.num .div4{
       color: #ffffff;
       background: #EB5E00;
    }
    .like-box-middle-condition{
        background: #ffffff;
        padding-left: 48px;
        padding-bottom: 9px;
    }
    .like-box-middle-condition-div1,.like-box-middle-condition-div2,.like-box-middle-condition-div3{
        border-bottom: 1px dotted #dddddd;
    }
    .like-box-middle-condition-div1>div:nth-of-type(1){
        height: 56px;
        font-family: "PingFangSC-Thin";
        font-size: 14px;
        font-weight: 100;
        color: #666666;
        line-height: 56px;
        float: left;
        margin-right: 17px;
    }
    .like-box-middle-condition-div1>div:nth-of-type(2){
        width: 1075px;
        float: left;
        margin-top: 17px;
    }
    .like-box-middle-condition-div1>div:nth-of-type(2)>div{
        float: left;
        width: 122px;
        height: 24px;
        margin-right: 32px;
        background-color: #fed18e;
        margin-bottom: 5px;
        font-size: 12px;
        color: #ffffff;
        text-align: center;
        line-height: 24px;
        cursor: pointer;
    }
    .like-box-middle-condition-div1>div:nth-of-type(2)>div>a{
        text-decoration: none;
        margin-left: 6px;
        color: #ffffff;
    }
    .like-box-middle-condition-div1>div:nth-of-type(2)>div:nth-of-type(7n){
        margin-right: 0;
    }
    .like-box-middle-condition-div2>div:nth-of-type(1){
        height: 45px;
        line-height: 45px;
        font-family: "PingFangSC-Thin";
        font-size: 14px;
        font-weight: 100;
        color: #666666;
        float: left;
        margin-right: 48px;
    }
    .type1{
        width: 900px;
        float: left;
        height: 31px;
        margin-top: 12px;
        overflow: hidden;
    }
    .active1{
        height: 270px;
    }
    .type1>div{
        width: 91px;
        float: left;
        font-size: 14px;
        color: #333333;
        margin-right: 60px;
        margin-bottom: 15px;
        cursor: pointer;
    }
    .type1>div:nth-child(6n){
        margin-right: 0;
    }
    .like-box-middle-condition-div2>div:nth-of-type(3){
        float: left;
        height: 45px;
        line-height: 45px;
        margin-left: 65px;
        font-size: 14px;
        font-weight: 600;
        color: #333333;
        cursor: pointer;
    }
    .like-box-middle-condition-div3>div:nth-of-type(1){
        height: 45px;
        line-height: 45px;
        font-family: "PingFangSC-Thin";
        font-size: 14px;
        font-weight: 100;
        color: #666666;
        float: left;
        margin-right: 19px;
    }
    .type2{
        width: 900px;
        float: left;
        height: 26px;
        margin-top: 12px;
        overflow: hidden;
    }
    .active2{
        height: 58px;
    }
    .type2>div{
        float: left;
        font-size: 14px;
        color: #333333;
        margin-right: 55px;
        margin-bottom: 6px;
        cursor: pointer;
    }
    .type2>div:nth-child(7n){
        margin-right: 0;
    }
    .like-box-middle-condition-div3>div:nth-of-type(3){
        float: left;
        height: 45px;
        line-height: 45px;
        margin-left: 65px;
        font-size: 14px;
        font-weight: 600;
        color: #333333;
        cursor: pointer;
    }
    .like-box-middle-condition-div4{
        height: 42px;
        line-height: 42px;
    }
    .like-box-middle-condition-div4>div{
       float: left;
    }
    .nian{
        font-family: "PingFangSC-Thin";
        font-size: 14px;
        font-weight: 100;
        color: #666666;
        margin-right: 25px;
    }
    .input-box{
        margin-right: 5px;
    }
    input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{
        -webkit-appearance: none !important; 
       }
    input[type="number"]{
        -moz-appearance:textfield;
        outline: none;
        width: 77px;
        height: 28px;
        border-radius: 4px;
        border: solid 1px #d6d6d6;
       }
    .input-box>span{
        font-size: 14px;
        color: #333333;
    }
    .confire{
        width: 72px;
        height: 28px;
        border-radius: 4px;
        background-color:#EB5E00;
        font-size: 14px;
        line-height: 28px;
        text-align: center;
        color: #ffffff;
        margin-top: 7px;
        cursor: pointer;
    }
    .tip{
        color: red;
        font-size: 14px;
        height: 42px;
        line-height: 42px;
        margin-left: 10px;
    }
    .like-box-detail{
        margin-top: 20px;
        padding: 27px 48px 102px;
        background-color: #ffffff;
        /* margin-bottom: 102px; */
    }
    .like-box-detail-total{
        font-size: 24px;
        margin-bottom: 28px;
        color: #000000;
    }
    .like-box-detail-total>span{
        color: #EB5E00;
    }
    .like-box-detail-box{
        height: 150px;
        border: solid 1px #e6e6e6;
        margin-bottom: 16px;
    }
    .like-box-detail-box>div{
        float: left;
    }
    .like-box-detail-box-img{
        width: 118px;
        height: 118px;
        border: solid 1px #e6e6e6;
        margin: 16px 84px 16px 16px;
    }
    .like-box-detail-box-img>img{
        width: 118px;
        height: 118px;
    }
    .like-box-detail-box-des{
        width: 735px;
        margin-top: 40px;
    }
   .like-box-detail-box-des>div>span{
    font-size: 14px;
    vertical-align: middle;
   }
   .like-box-detail-box-des>div:nth-of-type(1){
    margin-bottom: 20px;
   }
     .like-box-detail-box-des>div>span:nth-child(odd){
        color: #9E8E85;
     }
    .like-box-detail-box-des>div>span:nth-child(even){
        margin-right: 35px;
    }
    .like-box-detail-box-do{
        width: 143px;
        height: 120px;
        font-size: 12px;
        margin-top: 16px;
        text-align: center;
        /* padding-left: 25px; */
        border-left: 1px dotted  #e6e6e6;
        line-height: 100px;
    }
    .like-box-detail-box-do>p{
        margin-bottom: 24px;
        color: #EB5E00;
        cursor: pointer;
    }
    #p1{
        margin-top: 11px;
        color: #4a90e2;
    }
    .like-page{
        text-align: center;
        margin-top:40px;
    }
    .like-box-nodata{
        text-align: center;
        margin-top: 60px;
    }
    .like-box-nodata>div:nth-of-type(1){
        margin: 36px 0 48px;
        font-size: 14px;
        color: #333333;
    }
    .like-box-nodata>div:nth-of-type(2){
        width: 144px;
        height: 38px;
        margin: 0 auto;
        border-radius: 4px;
        line-height: 38px;
        background-color:#EB5E00;
        font-size: 16px;
        cursor: pointer;
        text-align: center;
        color: #ffffff;
    }
    
</style>
